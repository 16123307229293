.city-wrapper {
    position: fixed;
    //bottom: -10px;
    bottom: 0px;
    left: 0;
    width: 100%;
}

@media(max-width: 600px) {
    .city-wrapper {
        left: -50%;
        width: 200%;
    }

}