body {
  background-color: var(--color-light);
  color: var(--color-text) !important;
  height: 100%;
  box-sizing: border-box;
}
html {
  height: 100%;
  box-sizing: border-box;
}
#root {
  height: 100%;
  box-sizing: border-box;
}
.App {
  height: 100%;
  box-sizing: border-box;
}
h1, h2, h3, p {
  color: var(--color-text);
}
