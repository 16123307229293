.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;

    .logo-wrapper.md {
        display: block;
    }

    .logo-wrapper.sm {
        display: none;
    }

    .menu-wrapper {
        a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 10px;
            background-color: #fff;
            text-decoration: none;
            color: #7b9ca3;
            font-weight: 800;
            margin-left: 15px;
            font-size: 1.3em;
        }

        .active-item {
            color: #fff;
            background-color: #7b9ca3;
        }
    }
}

@media(max-width: 700px) {


    .header-container {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px;

        .logo-wrapper.md {
            display: none;
        }

        .logo-wrapper.sm {
            display: block;
        }

        .menu-wrapper {
            padding-top: 10px;

            a {
                margin-left: 0px;
                font-size: 1em;
                margin: 10px;
            }
        }
    }
}