.contact-page-container {
    width: 100%;
    background-color: #dce4e6;
    height: 100%;
    position: absolute;

    .page-body {
        >div {
            padding: 5px;

            >p {
                margin: 0;
                padding: 5px 0;

                >a {
                    text-decoration: none;
                    color: var(--color-primary);
                    display: block;
                    text-align: center;
                    font-weight: 600;
                    margin-top: 10px;
                }
            }
        }
    }
}