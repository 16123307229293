button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.add-image-btn {
    display: inline-block;
    position: relative;
    background-color: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    //filter: drop-shadow(0 2px 0 #ccc);
    width: 120px;
    height: 120px;
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
    background-color: var(--color-light);

    >input {
        position: absolute;
        top: -99px;
    }

    >div {
        display: flex;
        align-items: center;
        width: 110px;
        height: 110px;
        border-radius: 10px;
        //border: 1.5px dashed #999;
        border-width: 2px;
        border-style: dashed;
        border-color: var(--color-medium);
        box-sizing: border-box;
        padding: 10px;

        >div {
            text-align: center;
            color: var(--color-text);
            width: 100%;

            svg {
                font-size: 30px;
                color: var(--color-medium);
            }

            >span {
                display: block;
                font-weight: 500;
                font-size: .8em;
            }
        }

        >label {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            cursor: pointer;
        }
    }
}

.fab {
    background-color: var(--color-primary) !important;
    color: #fff !important;
}

.card-btn {
    background-color: var(--color-light);
    padding: 10px;
    border-radius: 10px;
    width: 80px;
    height: 70px;
    margin: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: var(--color-text);
    text-align: center;

    flex-direction: column;

    //filter: drop-shadow(0 1.5px 0 #ccc);
    >span {
        font-weight: 300;
        font-size: .9em;
        position: relative;
        //top: -5px;
        line-height: 1;
    }

    >strong {

        font-size: .9em;
        position: relative;
        top: -5px;
    }

    >div {
        position: absolute;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        bottom: -14px;
        right: 50%;
        margin-right: -14px;

        //background-color: red;

        >svg:nth-of-type(1) {
            position: absolute;
            top: -1px;
            left: -1px;
            color: var(--color-light);
            font-size: 28px;

        }

        >svg:nth-of-type(2) {
            position: absolute;
            top: 1px;
            left: 1px;
            font-size: 24px;
            color: var(--color-primary);

        }
    }
}

.card-btn.lg {

    padding: 15px;
    border-radius: 20px;
    width: 100px;
    height: 80px;
    margin: 20px;



    >span {

        font-size: 1.1em;

    }

    >strong {

        font-size: 1.1em;

        top: -5px;
    }

    >div {

        width: 40px;
        height: 40px;
        bottom: -20px;

        margin-right: -20px;

        >svg:nth-of-type(1) {

            font-size: 40px;
        }

        >svg:nth-of-type(2) {

            top: -1px;
            left: -1px;
            font-size: 40px;


        }
    }
}

@media (max-width: 480px) {
    .card-btn.lg {
        >span {
            font-size: .9em;
        }

        >strong {
            font-size: .9em;
            top: -5px;
        }

        >div {

            width: 28px;
            height: 28px;
            bottom: -14px;
            margin-right: -14px;

            >svg:nth-of-type(1) {
                font-size: 28px;
            }

            >svg:nth-of-type(2) {
                top: 1px;
                left: 1px;
                font-size: 24px;
            }
        }
    }
}