.services-page-container {
    width: 100%;
    background-color: #dce4e6;
    height: 100%;
    position: absolute;

    .page-body {
        >div {
            padding: 5px;

            >div {
                margin-bottom: 10px;

                >h2 {
                    margin: 0;
                    padding: 0 0 0 0;
                    font-weight: 700;
                    font-size: 1em;
                    text-align: center;
                }

                >p {
                    margin: 0;
                    padding: 5px 0;
                }
            }

        }
    }
}