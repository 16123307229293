.page-container {
    padding-bottom: 100px;

    >.page-header {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >h1 {
            font-weight: 900;
            margin: 0px;
            padding: 0px;
            display: block;
            font-size: 26px;
        }
    }

    >.page-body {
        padding: 10px;
        box-sizing: border-box;

    }

    >.fab {
        position: fixed;
        bottom: 20px;
        right: 20px
    }
}

.page-body {
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        background-color: #dce4e6d4;
        border-radius: 20px;

        >h1 {
            font-weight: 900;
            font-size: 1.3em;
            margin: 0;
            padding: 10px;
        }

        >p {
            padding: 10px;
            text-align: center;
            font-weight: 300;
            margin: 0;
        }
    }


}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    >.modal-title {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        flex: 1;

        >h2 {
            font-weight: 900;
            font-size: 1.4em;
            margin: 0px;
            padding: 0px;
        }

        >span {
            font-weight: 300;
            font-size: 1em;
        }
    }

    >.modal-start-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    >.modal-end-btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

.no-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin-top: 40px;


    >svg {
        font-size: 80px;
        color: var(--color-text-tint);

    }

    >span {
        font-size: .9em;
        color: var(--color-text-tint);
        display: block;
        width: 180px;
        text-align: center;
    }
}

reach-portal {
    >div {
        z-index: 9999;
    }
}

input {
    width: 100%;
    height: 30px;
    line-height: 25px;
    border: none;
    font-size: .9em;
    padding: 5px;
    color: var(--color-text);
    font-weight: 500;
    outline: none;
    background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #fff inset !important;
}

.field-description {
    font-weight: 300;
    font-style: italic;
    display: block;
    padding: 3px;

}

.expanded-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;

}

@media screen and (max-width: 600px) {
    .page-container {
        >.page-header {
            >h1 {
                font-size: 22px;
            }
        }
    }
}